import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class ReservationCalcSettingService {

    async list() {
        promise = api.client.get(config.end_point.facility.reservation_calc_setting.base);
        return promise;
    }

    async show(id) {
        promise = api.client.get(config.end_point.facility.reservation_calc_setting.base + `?parking_id=${id}`);
        return promise;
    }

    async store(data) {
        promise = api.client.post(config.end_point.facility.reservation_calc_setting.base, data);
        return promise;
    }

    async update(id, data) {
        promise = api.client.put(config.end_point.facility.reservation_calc_setting.base + '/' + id, data);
        return promise;
    }

    async upsert(data){
        promise = api.client.post(config.end_point.facility.reservation_calc_setting.upsert, data);
        return promise;
    }

}

export const reservationCalcSettingService = new ReservationCalcSettingService();
