import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { alertService } from "@/services/alert/alert.service";
import { reservationCalcSettingService } from "@/services/facility/reservation-calc-setting/reservation-calc-setting.service";
import config from "@/config/app.config";
import moment from "moment";
import allLocales from "@fullcalendar/core/locales-all";
import { EventBus } from "@/event-bus";

export default {
    components: {
        FullCalendar,
    },
    data() {
        return {
            data: {
                airport_id: config.airport_id,
                parking_type: 1,
                calc_setting: [],
            },
            error: null,
            config: {},
            calendarOptions: {
                locales: allLocales,
                locale: this.$i18n.locale,
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: "dayGridMonth",
                selectable: true,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                eventOverlap: false,
                events: [],
                eventContent: this.reRenderCalendar,
            },
            days: [],
            form: {
                id: '',
                parking_id: '',
                date: '',
                unit_time: '',
                unit_toll_first_time: '',
                unit_toll: '',
                day_toll_max: '',
                penalty_unit_toll: '',
                penalty_day_toll_max: '',
                discount_day_toll_max: '',
                discount_threshold_days: '',
                disable_day_toll_max: 31,
                note: '',
            },
            bulkForm: {
                id: '',
                parking_id: '',
                date: '',
                unit_time: '',
                unit_toll_first_time: '',
                unit_toll: '',
                day_toll_max: '',
                penalty_unit_toll: '',
                penalty_day_toll_max: '',
                discount_day_toll_max: '',
                discount_threshold_days: '',
                disable_day_toll_max: 31,
                note: '',
            },
            calendar: {},
            calendarApi: null,
        };
    },
    watch: {
        parkingType() {
            if (this.$route.params.id) {
                return;
            }
            this.calendarOptions.events = [];
            this.days = [];
        },
    },
    async mounted() {
        this.calendarApi = this.$refs.fullCalendar.getApi();        
        await this.show(this.$route.params.id);

        EventBus.$on("changeLocal", () => {
            this.calendarOptions.locale = this.$i18n.locale;
        });
    },
    computed: {
        dates() {
            return this.days.map((day) => day.date);
        },
        attributes() {
            return this.dates.map((date) => ({
                highlight: true,
                dates: date,
            }));
        },
        calendars() {
            return document.getElementsByClassName("vc-title");
        },
        parkingType() {
            return this.data.parking_type;
        },
    },
    methods: {
        handleDateSelect(selectInfo) {
            this.calendar = selectInfo;
            this.showSetting();
        },

        handleEventClick(clickInfo) {
            this.calendar = clickInfo.event;
            this.showSetting();
        },

        showSetting(){
            const calendarDate = this.calendar.startStr;
            this.form.date = calendarDate;
            const findIndex = this.calendarOptions.events.map((i) => i.start).indexOf(calendarDate);
            if(findIndex == -1){
                this.form.id = '';
                this.form.parking_id = this.$route.params.id;
                this.form.unit_time = '';
                this.form.unit_toll_first_time = '';
                this.form.unit_toll = '';
                this.form.day_toll_max = '';
                this.form.penalty_unit_toll = '';
                this.form.penalty_day_toll_max = '';
                this.form.discount_day_toll_max = '';
                this.form.discount_threshold_days = '';
                this.form.note = '';
                return;
            }
            const setting = this.data.calc_setting[findIndex];
            this.form.id = setting.id;
            this.form.parking_id = setting.parking_id;
            this.form.unit_time = setting.unit_time;
            this.form.unit_toll_first_time = setting.unit_toll_first_time;
            this.form.unit_toll = setting.unit_toll;
            this.form.day_toll_max = setting.day_toll_max;
            this.form.penalty_unit_toll = setting.unit_toll;
            this.form.penalty_day_toll_max = setting.penalty_day_toll_max
            this.form.discount_day_toll_max = setting.discount_day_toll_max;
            this.form.discount_threshold_days = setting.discount_threshold_days;
            this.form.note = setting.note;
        },

        async store$() {
            this.error = null;
            var callback = async () => {
                await reservationCalcSettingService
                    .store(this.form)
                    .then(async () => {
                        alertService.success(
                            this.$t("facility.global.success"),
                            this.$t("facility.inventory.record-updated")
                        );
                        await this.show(this.$route.params.id);
                    })
                    .catch((response) => {
                        this.error = error.message;
                    });
            };
            alertService.confirmation(
                callback,
                this.$t("facility.global.confirmation"),
                this.$t("facility.global.are-you-sure")
            );
        },

        async update$() {
            this.error = null;
            var callback = async () => {
                await reservationCalcSettingService
                    .update(this.form.id, this.form)
                    .then(async () => {
                        alertService.success(
                            this.$t("facility.global.success"),
                            this.$t("facility.inventory.record-updated")
                        );
                        await this.show(this.$route.params.id);
                    })
                    .catch((response) => {
                        this.error = error.message;
                    });
            };
            alertService.confirmation(
                callback,
                this.$t("facility.global.confirmation"),
                this.$t("facility.global.are-you-sure")
            );
        },

        async show(parkingId) {
            alertService.loading();
            var response = await reservationCalcSettingService.show(parkingId);
            this.data = response.data[0];
            this.calendarOptions.events = [];
            this.data.calc_setting.forEach((setting) => {
                this.calendarOptions.events.push({
                    start: setting.date,
                    end: setting.date,
                    title: `
                        ${setting.unit_time}分 ${setting.unit_toll}円
                        <br>
                        ${setting.day_toll_max == undefined ? "" : "1日 " + setting.day_toll_max + "円"}
                    `,
                });
            });
            alertService.close();
        },

        onDayClick(day) {
            if (
                moment(new Date()).format("Y-MM-DD") >
                moment(day.id).format("Y-MM-DD")
            ) {
                return;
            }

            const idx = this.days.findIndex((d) => d.id === day.id);
            if (idx >= 0) {
                this.days.splice(idx, 1);
            } else {
                this.days.push({
                    id: day.id,
                    date: day.date,
                });
            }
        },

        async batchInput() {
            this.days.forEach((day) => {
                const setting = {
                    id: null,
                    parking_id: this.$route.params.id,
                    date: day.id,
                    unit_time: this.bulkForm.unit_time,
                    unit_toll_first_time: this.bulkForm.unit_toll_first_time,
                    unit_toll: this.bulkForm.unit_toll,
                    day_toll_max: this.bulkForm.day_toll_max,
                    penalty_unit_toll: this.bulkForm.unit_toll,
                    penalty_day_toll_max: this.bulkForm.penalty_day_toll_max,
                    discount_day_toll_max: this.bulkForm.discount_day_toll_max,
                    discount_threshold_days: this.bulkForm.discount_threshold_days,
                    disable_day_toll_max: this.bulkForm.disable_day_toll_max,
                    note: this.bulkForm.note,
                }
                const findIndex = this.calendarOptions.events.map((i) => i.start).indexOf(day.id);
                if(findIndex == -1){
                    this.data.calc_setting.push(setting);
                    return;
                }
                setting.id = this.data.calc_setting[findIndex].id;
                this.data.calc_setting[findIndex] = setting;
            });
            console.log('Batch input', this.data)

            var callback = async () => {
                await reservationCalcSettingService
                    .upsert(this.data)
                    .then(async () => {
                        alertService.success(
                            this.$t("facility.global.success"),
                            this.$t("facility.inventory.record-updated")
                        );
                        this.$refs["batch-modal"].hide();
                        await this.show(this.$route.params.id);
                    })
                    .catch((response) => {
                        this.error = error.message;
                    });
            };
            alertService.confirmation(
                callback,
                this.$t("facility.global.confirmation"),
                this.$t("facility.global.are-you-sure")
            );
        },

        reRenderCalendar(arg) {
            return {
                html :  `<strong>
                            ${arg.event.title}
                        </strong>`,
            };
        },

        selecAll() {
            let daysInMonth = moment(this.yearMonth, "YYYY-MM").daysInMonth();

            for (let index = 0; index < daysInMonth; index++) {
                let dates =
                    moment(this.yearMonth, "YYYY-MM").format("Y") +
                    "-" +
                    moment(this.yearMonth, "YYYY-MM").format("MM") +
                    `-${index + 1}`;
                let formatedDate = moment(dates).format("Y-MM-DD");
                if (!this.days.map((i) => i.id).includes(formatedDate)) {
                    if (
                        moment(new Date()).format("Y-MM-DD") <=
                        moment(dates).format("Y-MM-DD")
                    ) {
                        this.days.push({
                            date: new Date(dates),
                            id: formatedDate,
                        });
                    }
                }
            }
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                // 46 is dot
                $event.preventDefault();
            }
        },

        changeMonth(data) {
            this.yearMonth = moment(`${data.year}-${data.month}`).format(
                "Y-MM"
            );
        },

        openBatchInput() {
            if (this.data.parking_type == 1) {
                this.days = [];
            }
        },
    },
};
